




















import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import EntranceTeacherHistoryHeader from '@/components/organisms/EntranceTeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import EntranceInfomeation from '@/components/organisms/EntranceInfomeation.vue'
import { EntranceChoiceSchool } from '@/models/api/studentGoal'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { EntranceInfo } from '@/models/api/history/entrance.ts'
import _ from 'lodash'

@Component({
  components: {
    StudentHistorySidebar,
    EntranceTeacherHistoryHeader,
    ColoredBox,
    TitleBase,
    TitleTextBase,
    EntranceInfomeation,
    TeacherHistoryBottomPanel,
    ButtonBase,
  },
})
export default class TargetSituation extends Mixins(StudentGoalApi, ClassModeMethods) {
  private get classMode() {
    return this.CLASS_MODE.ENTRANCE
  }

  private studentUserId = Vue.prototype.$cookies.get('dataGdls').student?.userId || 0

  private entranceData: EntranceInfo = { school: '-', date: '-', countdown: '-' }

  private currentGoal: EntranceChoiceSchool | null = null

  private async mounted() {
    Vue.prototype.$loading.start()
    this.currentGoal = await this.loadCurrentGoal(this.classMode, this.studentUserId)
    await this.setEntranceData()
    Vue.prototype.$loading.complete()
  }

  private async setEntranceData(): Promise<void> {
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceInformation`, {
        params: { userId: this.studentUserId },
      })
      .then((res: any) => {
        if (!_.isEmpty(res.data)) this.entranceData = res.data
      })
  }
}
